import React, { useEffect } from 'react';
import { ApiRequestLogger } from "../../ApiRequestLogger";

const withLogger = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const logComponentRenders = (props : any) => {
    const logger = new ApiRequestLogger();
    useEffect(() => {
      // Log data on component mount
      logger.debug(`Component ${WrappedComponent.name} mounted.`);
      return () => {
        // Log data on component unmount
        logger.debug(`Component ${WrappedComponent.name} unmounted.`);
      };
    }, []);

    useEffect(() => {
      // Log data on component update
      logger.debug(`Component ${WrappedComponent.name} updated.`);
    });

    return <WrappedComponent {...props}/>;
  };

  return logComponentRenders;
};

export default withLogger;
